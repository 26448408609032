<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
        snackbarText
      }}</v-snackbar>
  
      <v-card-title class="grid-close">
        <span class="headline">Nueva tarifa por KM</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          :loading="loading"
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  outlined
                  :loading="loading"
                  :rules="[
                        rules.isInteger,
                        rules.isPositive,
                    ]"
                  rounded
                  label="Distancia"
                  :disabled="!!reason"
                  v-model.number="item.distance"
                  @keypress="filterNumber"
                />
                <v-text-field
                  outlined
                  :loading="loading"
                  :rules="[rules.required, rules.isGreaterThanZero]"
                  rounded
                  label="Tarifa"
                  v-model.number="item.rate"
                  @keypress="filterNumber"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
  
      <v-card-actions>
        <v-container fluid>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" sm="6" md="6">
              <v-row justify="start">
                <v-switch v-model="item.active" label="Activo"></v-switch>
              </v-row>
            </v-col>
  
            <v-col cols="6" sm="6" md="6">
              <v-row justify="end">
                <v-btn
                  @click="reason ? update() : save()"
                  class="save-btn mt-4"
                  color="primary"
                  :disabled="loading"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { db } from "@/firebase"
import { mapState } from "vuex";

export default {
  name: "add-rate",
  props: ["reason"],

  data() {
    return {
      item: {
        active: true,
      },

      rules: {
        required: (value) => {
          return (value !== undefined && value !== "") || "Obligatorio"
        },
        isInteger: (value) => Number.isInteger(value) || "Debe ser un número entero",
        isPositive: (value) => value >= 0 || "Debe ser mayor o igual a cero",
        isGreaterThanZero: (value) => value > 0 || "Debe ser mayor a cero"
      },
      snackbar: false,
      snackbarText: "",

      loading: true,
    };
  },

  computed: {
    ...mapState(["user"]),
  },
  methods: {
    // handleAnimation: function (anim) {
    //   this.anim = anim;
    //   anim.setSpeed(this.animationSpeed);
    // },
    filterNumber(e) {
      const start = e.target.selectionStart
      const end = e.target.selectionEnd
      const insert = e.key.toString()
      const current = e.target.value.toString()

      const result = current.substring(0, start) + insert + current.substring(end)
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(result)) {
        e.preventDefault()
      } else {
        return true
      }
    },
    async update() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        await db
          .collection("settings")
          .doc("driverDistanceRates")
          .collection("rates")
          .doc(this.reason[".key"])
          .update({
            rate: this.item.rate,
            active: this.item.active,
            modifiedAt: new Date(),
            modifiedBy: this.user[".key"],
          })
          .then(() => {
            this.$emit("success");
          })
          .catch((err) => {
            this.snackbarText = err.message;
            this.snackbar = true;
            this.loading = false;
          });
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const doc = await db
          .collection("settings")
          .doc("driverDistanceRates")
          .collection("rates")
          .doc(`${this.item.distance}`)
          .get()

        if (doc.exists) {
          this.loading = false
          this.snackbarText = "Este valor ya existe";
          this.snackbar = true;
          return
        }

        await db
          .collection("settings")
          .doc("driverDistanceRates")
          .collection("rates")
          .doc(`${this.item.distance}`)
          .set({
            rate: this.item.rate,
            active: this.item.active,
            createdAt: new Date(),
            createdBy: this.user[".key"],
            deleted: false,
          })
          .then(() => {
            this.$emit("success");
          })
          .catch((err) => {
            this.snackbarText = err.message;
            this.snackbar = true;
            this.loading = false;
          });
      }
    },
  },
  async mounted() {
    if (this.reason) {
      this.item = {
        ...this.reason,
        distance: Number.parseInt(this.reason[".key"])
      }
    }
    
    this.loading = false;
  },
};
</script>